/* src/components/Services/Services.css */

.services-section {
  padding: 100px 50px;
  text-align: center;
  background-color: #f5f5f5;
}

.services-section h2 {
  font-size: 2.5rem;
  margin-bottom: 50px;
}

.services-container {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap; /* Ensure responsiveness */
}

.service {
  max-width: 300px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.service h3 {
  margin-top: 15px;
  font-size: 1.5rem;
}

.service p {
  margin-top: 10px;
  font-size: 1rem;
  color: #555;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .service {
    max-width: 100%;
  }
}