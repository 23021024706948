/* src/App.css */

.App {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Particles Styling */
#tsparticles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Places particles behind all content */
}

/* Global Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #0d47a1; /* Should match Particles background */
}

a {
  text-decoration: none;
  color: inherit;
}