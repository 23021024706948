/* src/components/Footer.css */
.footer {
    padding: 50px;
    background-color: #0d47a1;
    color: #fff;
    text-align: center;
  }
  
  .social-icons {
    margin-top: 20px;
  }
  
  .social-icons a {
    margin: 0 10px;
    color: #fff;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #ff5722;
  }