/* src/components/About.css */
.about-section {
    padding: 100px 50px;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  .about-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .about-section p {
    font-size: 1.1rem;
    max-width: 800px;
    margin: 0 auto 50px auto;
  }
  
  .icons {
    display: flex;
    justify-content: center;
    gap: 50px;
  }
  
  .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icon h3 {
    margin-top: 10px;
    font-size: 1.2rem;
  }