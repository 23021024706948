/* src/components/Header/Header.css */

.header {
  position: relative;
  height: 100vh;
  color: #fff;
  text-align: center;
  padding-top: 60px; /* Adjust if you have a fixed navbar */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px; /* Adjusted padding */
  position: fixed;
  top: 0;
  width: 100%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 1000;
}

/* Transparent Navbar */
.navbar-transparent {
  background: rgba(0, 0, 0, 0.0); /* Fully transparent */
  box-shadow: none;
}

/* Solid Navbar */
.navbar-solid {
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent dark background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 2rem;
  font-weight: bold;
  margin-left: 10px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  padding-right: 25px;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #ff5722;
}

/* Hamburger Icon */
.hamburger {
  display: none;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
}

/* Drawer Styles */
.drawer {
  position: fixed;
  top: 90px; /* Height of the navbar */
  right: 0;
  width: 250px;
  height: calc(100% - 60px);
  background-color: #0d47a1;
  box-shadow: -2px 0 5px rgba(0,0,0,0.5);
  z-index: 999;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%); /* Hidden */
}

.drawer.open {
  transform: translateX(0); /* Slide in */
}

.drawer.closed {
  transform: translateX(100%); /* Slide out */
}

.drawer ul {
  list-style: none;
  padding: 20px;
}

.drawer ul li {
  margin: 20px 0;
}

.drawer ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s;
}

.drawer ul li a:hover {
  color: #ff5722;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide nav links on small screens */
  }

  .hamburger {
    display: block; /* Show hamburger icon on small screens */
  }
}

/* Header Content */
.header-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-content h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.header-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.btn {
  padding: 10px 20px;
  background-color: #ff5722;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #e64a19;
}

/* Responsive Header Content */
@media (max-width: 768px) {
  .header-content h2 {
    font-size: 2rem;
  }

  .header-content p {
    font-size: 1rem;
  }
}