/* src/components/Contact.css */
.contact-section {
    padding: 100px 50px;
    background-color: #f5f5f5;
    text-align: center;
  }
  
  .contact-section h2 {
    font-size: 2.5rem;
    margin-bottom: 50px;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .contact-form button {
    padding: 15px;
    background-color: #ff5722;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }